import React from "react";
import Layout from "../layout";
import imgBackground from "../assets/img/background-header-puntoian.jpg";
import ScrollDownIcon from "../components/ScrollDownIcon";

export default function Community() {
    console.log("comunnity")
    return (
        <Layout>
            <section id="comunidad">
                <div>
                    <img
                        loading="lazy"
                        src={imgBackground}
                        alt="Nuestra comunidad"
                    />
                    <ScrollDownIcon />
                </div>
                <div className="comunidad-tarifario">
                    <h2>¿Con qué servicios contamos en Punto Ian?</h2>
                    <p>
                        ¡Gracias por preguntar! <br />
                        Contamos con el espacio ideal para que puedas concentrarte en lo que más
                        te gusta y le des rienda suelta a tu creatividad.<br />
                    </p>
                    <ul>
                        <li>Internet alta velocidad.</li>
                        <li>Lingas de seguridad para notebooks.</li>
                        <li>Cocina totalmente equipada.</li>
                        <li>Baño para personas con movilidad reducida.</li>
                        <li>Mobiliario cómodo y moderno.</li>
                        <li>Acceso a Café Bonafide.</li>
                        <li>Luz natural.</li>
                        <li>
                            Y lo más importante: una comunidad con muchas ganas
                            de crecer.
                        </li>
                    </ul>
                </div>
                <div className="comunidad-manual">
                    <h1>Manual de Convivencia</h1>
                    <p>
                        A continuación les dejamos algunos lineamientos a tener
                        en cuenta para asegurarnos de que nuestro tiempo en •ian
                        Co. sea tan ameno y productivo como sea posible.
                    </p>
                    <a
                        href="https://drive.google.com/file/d/1-psweVSSlXfHVqAresbBlORjreaMlZgC/view"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="hvr-pop">Ver el manual</button>
                    </a>
                </div>
            </section>
        </Layout>
    );
}
