import * as React from "react";

function Whatsapp({ primaryColor, secondaryColor }) {
    return (
        <svg
            id="prefix__Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 682 682"
            xmlSpace="preserve"
        >
            <style>{".prefix__st0{fill-rule:evenodd;clip-rule:evenodd}"}</style>
            <path
                className="prefix__st0"
                d="M567.2 113.9C507.3 54 427.8 21 343 21 168.4 21 26.3 163.1 26.2 337.8c0 55.8 14.6 110.3 42.3 158.4L23.6 660.4l168-44.1c46.3 25.2 98.4 38.5 151.4 38.6h.1c174.6 0 316.8-142.1 316.8-316.8 0-84.7-32.9-164.3-92.7-224.2z"
                fill={primaryColor}
            />
            <path
                d="M343 601.4h-.1c-47.3 0-93.6-12.7-134-36.7l-9.6-5.7-99.7 26.1 26.6-97.2-6.3-10C93.6 436 79.7 387.6 79.7 337.8c.1-145.2 118.2-263.3 263.5-263.3 70.3 0 136.5 27.4 186.2 77.2s77.1 115.9 77.1 186.3c-.2 145.2-118.3 263.4-263.5 263.4z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={secondaryColor}
            />
            <path
                className="prefix__st0"
                d="M487.5 404.1c-7.9-4-46.8-23.1-54.1-25.8-7.3-2.6-12.5-4-17.8 4-5.3 7.9-20.4 25.8-25.1 31-4.6 5.3-9.2 5.9-17.2 2-7.9-4-33.4-12.3-63.7-39.3-23.5-21-39.4-46.9-44-54.8-4.6-7.9 0-11.8 3.5-16.2 8.6-10.6 17.2-21.8 19.8-27.1 2.6-5.3 1.3-9.9-.7-13.9s-17.8-42.9-24.4-58.8c-6.4-15.4-13-13.3-17.8-13.6-4.6-.2-9.9-.3-15.2-.3-5.3 0-13.9 2-21.1 9.9-7.3 7.9-27.7 27.1-27.7 66s28.4 76.6 32.3 81.9c4 5.3 55.8 85.2 135.2 119.5 18.9 8.2 33.6 13 45.1 16.7 19 6 36.2 5.2 49.9 3.1 15.2-2.3 46.8-19.2 53.4-37.6 6.6-18.5 6.6-34.3 4.6-37.6-1.8-3.1-7.1-5.1-15-9.1z"
                fill={primaryColor}
            />
        </svg>
    );
}

export default Whatsapp;
